import React, { useContext } from 'react';
import Image from 'next/image';
import { styled, useTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { HeadSetIcon } from "@/components/base/Icons/HeadSet";
import { BrainIcon } from "@/components/base/Icons/Brain";
import { TicketIcon } from '@/components/base/Icons/Ticket';
import { DashBoardIcon } from '@/components/base/Icons/DashBoard';
import InboxIcon from '@mui/icons-material/Inbox';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import { AppContext } from '@/contexts/AppContext';
import { SidebarItem } from './SideBarItem';
import { useLogout } from '@/hooks/useLogout';
import { useRouteName } from '@/hooks/useRoute';
import { useLanguage } from '@/hooks/useLanguage';
import { 
  Drawer, 
  Divider, 
  List, 
  ListItemIcon, 
  Box, 
  Tooltip, 
  IconButton 
} from '@mui/material';

interface Route {
  text: string;
  icon: any;
  url?: string;
}

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  justifyContent: 'flex-end',
  ...theme.mixins.toolbar,
}));

const Sidebar: React.FC = () => {
  const { lang } = useLanguage()
  const { navOpen, setNavOpen } = useContext(AppContext);
  const theme = useTheme();
  const { routeName } = useRouteName();
  const drawerWidth: number = navOpen ? 200 : 60;
  
  const handleDrawerClose = () => {
    setNavOpen(false)
  }

  const { handleLogout } = useLogout()

  const routes: Route[] = [
    { text: lang('menu.home'), url: '/home', icon: <HomeIcon className="text-2xl text-white" /> },
    // { text: lang('menu.inbox'), url: '/inbox', icon: <InboxIcon className="text-2xl text-white" /> },
    { text: lang('menu.copilot'), url: '/copilot', icon: <HeadSetIcon className="text-2xl text-white" /> },
    { text: lang('menu.brain'), url: '/brain/1', icon: <BrainIcon className="text-2xl text-white" /> },
    { text: 'Tickets', url: '/tickets/1', icon: <TicketIcon className="text-2xl text-white" /> },
    { text: 'Dashboard', url: '/dashboard', icon: <DashBoardIcon className="text-2xl text-white" /> },
  ]
  
  return (
    <Box className={`${!navOpen && 'hidden md:flex'}`}>
      <Drawer
        variant={navOpen ? 'persistent' : 'permanent'}
        anchor="left"
        open={navOpen}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            overflow: 'hidden'
          }
        }}
        className={`w-full ${navOpen ? 'w-full md:w-52' : 'w-0 md:w-14'}`}
        PaperProps={{
          className: "bg-secondary dark:bg-slate-700"
        }}
      >
        <DrawerHeader>
          {navOpen ? 
            <Box className="flex items-center justify-between w-full">
              <Image 
                src="/images/logo.svg" 
                height={35.75} 
                width={110} 
                alt="Cogni2" 
                className="filter invert brightness-0" 
                priority 
              />
              <Tooltip title={lang('close.menu')} arrow>
                <IconButton onClick={handleDrawerClose} className="text-white">
                  {theme.direction === 'ltr' ? <ChevronLeftIcon className="text-2xl" /> : <ChevronRightIcon className="text-2xl" />}
                </IconButton>
              </Tooltip>
            </Box>
          : <Tooltip title="Cogni2" arrow>
              <Box className="pt-0.5 w-full m-auto ml-2">
                <Image 
                  src="/images/cogni2-icon.png" 
                  alt="Cogni" 
                  width={40} 
                  height={34.20}  
                  priority
                  className='object-cover dark:filter dark:invert dark:brightness-0' 
                />
              </Box>
            </Tooltip>
          }
        </DrawerHeader>
        <Divider className="border-tertiary dark:border-slate-900 opacity-35" />
        <List disablePadding>
          {routes.map((route, index) => {
            const baseRoute = route?.url?.split('/')?.[1] || '';
            const isActive: boolean = routeName?.replace('/', "")?.startsWith(baseRoute) || false

            return (
              <SidebarItem 
                key={index} 
                text={route.text} 
                url={route.url} 
                icon={route.icon} 
                active={isActive} 
              />
            )})}
        </List>

        <ListItemIcon className="text-white mt-auto mb-5">
          <SidebarItem 
            text={lang('logout')}
            onClick={handleLogout} 
            icon={<LogoutIcon className="text-2xl" />}
            active={false} 
          />
        </ListItemIcon>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
