'use client'
import { ProfileFormValues } from "@/schemas/profile.schema";
import { Session } from "next-auth";
import { useSession } from "next-auth/react";

type UserConfig = Pick<Session, "user" | "config" | "signed_in">;

export const useUserSession = (): UserConfig & { 
  updateSessionUser: (updateObject: Partial<ProfileFormValues>) => void 
} => {
  const { data, update } = useSession()

  const updateSessionUser = async (updateObject: Partial<ProfileFormValues>) => {
    if (data?.user) {
      const updatedUser = { 
        ...data.user, 
        ...updateObject 
      }
      
      await update({ 
        ...data, 
        user: updatedUser 
      })
    }
  }
  
  return { 
    user: data?.user || {} as any,
    config: data?.config || {} as any,
    signed_in: data?.signed_in || '' as any,
    updateSessionUser
  }
}