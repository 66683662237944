import React, { ReactNode } from 'react';
import { NavBar } from '@/components/layouts/NavBar';
import SideBar from '@/components/layouts/SideBar';
import Grid from '@mui/material/Grid';
import ErrorProvider from '@/providers/ErrorProvider';
import { twMerge } from 'tailwind-merge'

type AppWrapperProps = {
  login?: boolean;
  className?: string;
  children: ReactNode;
}

export const AppWrapper = ({ login = false, className, children }: AppWrapperProps) => (
  <main className='appWrapper font-main'>
    {!login && <NavBar />}
    <div className={twMerge(!login ? `mainWrapper w-full md:w-[calc(100vw-57px)] md:ml-14 pl-4 pb-0 overflow-auto ${className || 'pt-4 pr-4'}` : '')}>
      <Grid container>
        <Grid item xs={12} className="grow">
          {!login && <SideBar />}
          <ErrorProvider>
            {children}
          </ErrorProvider>
          {!login && 
            <footer>
              <p className="text-slate-600 dark:text-slate-200 text-right text-small font-light py-1 mr-2">© Powered by <strong>Cogni2 AI</strong></p>
            </footer>
          }
        </Grid>
      </Grid>
    </div>
  </main>
)