'use client'
import { useContext } from 'react';
import { AppContext } from '@/contexts/AppContext';
import { signOut } from 'next-auth/react';

export const useLogout = () => {
  const { setLoadingSignOut } = useContext(AppContext);

  const handleLogout = async () => {
    localStorage.setItem('infoMessage', 'Você saiu com sucesso. Faça login novamente para continuar.');
    setLoadingSignOut(true)
    await signOut()
  }

  return { handleLogout };
};